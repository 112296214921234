/* keyframes */

@keyframes pisca {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

/*  */

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input[type="radio"]{
  width: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-scrollbar{
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: crimson;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgb(151, 0, 0);
}


.inativo {
  display: none;
}

.ativo {
  display: block;
}

.liEmRota::before {
  content: "# ";
}

.liEmRota::after {
  content: " - Em Rota";
}

.liRetornada::before {
  content: "# ";
}

.liRetornada {
  color: red;
}

.liRetornada::after {
  content: " - Retornada";
}

.liEntregue::before {
  content: "# ";
}

.liEntregue {
  color: green;
}

.liEntregue::after {
  content: " - Entregue";
}

.BorderBox {
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 2px;
}

.colorBlack {
  color: black;
}

.section-modal {
  background-color: coral;
  padding: 5px;
  display: grid;
}

.section-modal span span {
  color: black;
}

.section-modal span {
  display: inline;
  line-height: 0.em;
}

.modal-interno div section {
  overflow: scroll;
  height: 90vh;
}


.modal {
  width: 100vw;
  height: 100vh;
  background: rgb(0,0,0,.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  display: none;
  justify-content: center;
  align-items: top;
  text-align: center;
}

button.btn-closeModal {
  margin-bottom: 10px;
}

.modal.mostrar {
  display: flex;
  animation: slideUp .7s;
}

.divTextoModalSearch {
  padding: 20px;
  font-size: 20pt;
}

.divTextoModalSearch p {
  font-size: 18pt;
  text-align: left;
}


.loading {
  width: 200px;
  height: 200px;
}

.contentCenter {
  justify-content: center;
}

label {
  display: block;
  font-size: 17pt;
}


a:hover {
  color: crimson;
}

form {
  background-color: coral;
  text-align: center;
  justify-items: center;
  width: auto;
  padding: 15px;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 2px;
}

body {
  background-color: #F9F9F9;
  text-shadow: 0.01em 0.02em 0.05em black;
}

h2 {
  text-align: center;
  color: crimson;
  font-style: italic;
}

h3 {
  margin: 15px 0 15px 0;
  text-align: center;
  color: crimson;
  font-style: italic;
}


ul li span {
  color: crimson;
  font-style: italic;
  font-weight: 900;
}

p span {
  color: crimson;
  font-style: italic;
  font-weight: 900;
}

body, ul, li, p {
  margin: 0px;
  padding: 0px;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

textarea {
  width: 80%;
  justify-self: center;
  height: 400px;
  padding: 10px;
  border: 2px solid black;
  border-radius: 8px;
  font-size: 17pt;
}

input {
  width: 70%;
  border: 3px solid #000;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #000;
  padding: 2px;
  font-size: 17pt;
  text-align: center;
}

select {
  border: 2px solid black;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  justify-self: center;
  font-size: 18pt;
  text-align-last: center;
}


body button:hover {
  background-color: crimson;
}



li {
  list-style: none;
}

.bg-Coral {
  background-color: coral;
}

button {
  font-size: 15pt;
  width: auto;
  padding: 4px;
  background-color: coral;
  border: 0.5px solid black;
  border-radius: 8px;
  margin-top: 15px;
  justify-self: center;
  box-shadow: black 1px 1px 1px;
}


.btn-G {
  font-size: 20pt;
  width: auto;
  padding: 4px;
  background-color: coral;
  border: 2px solid black;
  border-radius: 8px;
  margin-top: 15px;
  justify-self: center;
  box-shadow: black 1px 1px 2px;
}

.btn-M {
  font-size: 15pt;
  width: auto;
  padding: 4px;
  background-color: coral;
  border: 2px solid black;
  border-radius: 8px;
  margin-top: 15px;
  justify-self: center;
  box-shadow: black 1px 1px 2px;
}

.btn-P {
  font-size: 12pt;
  padding: 4px;
  background-color: coral;
  width: auto;
  border: 2px solid black;
  border-radius: 8px;
  justify-self: center;
  box-shadow: black 1px 1px 2px;
  margin-top: 0;
}

#root {
  grid-template-columns: 1fr 25fr 1fr;
  grid-template-rows: 240px 1fr 50px;
  padding-top: 10px;
  gap: 5px;
  animation: slideUp 0.3s;
}

@media screen and (max-width: 500px) {
  #root {
    grid-template-rows: auto;
    grid-template-columns: 1fr 100fr 1fr;
    padding: 1px;
  }

  .container {
    margin-top: 0px !important;
  }

}

.grid {
  display: grid;
}

.container {
  grid-column: 2;
}

.footer {
  grid-column: 2;
}

/** Classes Menus **/

.header {
  grid-column: 2;
  margin-top: 5px;
  grid-template-columns: 250px 6fr 1fr;
  grid-template-rows: 248px;
  gap: 5px;
  align-items: center;
}

.aTopLogo {
  grid-column: 1;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}

.topLogo {
  border: 2px solid black;
  border-radius: 8px;
  width: 250px;
  height: 248px;
  box-shadow: black 1px 1px 2px;
}

.topLogo:hover {
  width: 252px;
  height: 250px;
}

.infoOperacao {
  grid-column: 2;
  height: 100%;
  padding: 5px;
  align-items: center;
  grid-template-rows: 162px 52px;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-areas: "leftContainer centerContainer rigthContainer"
                       "menuPrincipal menuPrincipal rigthContainer";
  gap: 5px;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 5px;
  background-color: coral;
}

.painelInf {
  grid-area: leftContainer;
  background-color: #F6F8FA;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  align-content: center;
  justify-content: center;
  border-radius: 8px;
}

.menuPrincipal {
  grid-area: menuPrincipal;
  background-color: #F6F8FA;
  border: 2px solid black;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
}

.menuPrincipal ul {
  display: inline;
  position: relative;
}

.menuPrincipal a {
  padding: 5px;
  border-radius: 8px;
}

.menuPrincipal ul li {
  border: 2px solid black;
  white-space: nowrap;
  text-align: center;
  border-radius: 8px;
  background-color: coral;
  margin: 2px;
  float: left;
}

.menuPrincipal ul li ul {
  display: none;
  float: none;
  border-radius: 8px;
  background-color: coral;
}

.menuPrincipal ul li:hover ul {
  display: block;
  position: absolute;
  border: 2px solid black;
  animation: slideLeft 0.3s;
}

.menuPrincipal ul li ul li {
  float: none;
  text-align: left;
  border: none;
}

.menuPrincipal ul li ul li ul{
  display: none !important;
  white-space: nowrap;
  margin-left: 98%;
  margin-top: -12%;
}

.menuPrincipal ul li ul li:hover ul {
  display: block !important;
  animation: slideLeft 0.3s;
}

.statusOperacao {
  grid-area: centerContainer;
  background-color: #F6F8FA;
  border: 2px solid black;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.statusOperacao ul li {
  text-align: left;
  margin-left: 20%;
}

.couriersOciososBase {
  grid-area: rigthContainer;
  background-color: coral;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 8px;
  grid-template-rows: 25px 1fr;
}

.couriersOciososBase p {
  color: crimson;
  text-align: center;
}

.couriersOciososBase ul {
  border-radius: 8px;
  border-top: 1px solid black;
  padding: 5px;
  background-color: #F6F8FA;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
}

.containerLogOut {
  height: 100%;
  width: 100%;
  padding: 2px;
  background-color: coral;
  grid-template-columns: 1fr 40fr 1fr;
  grid-template-rows: 1fr 40fr 1fr;
  border: 2px solid black;
  box-shadow: black 1px 1px 2px;
  border-radius: 8px;
  justify-items: center;
  align-items: center;
}

.containerLogOut p {
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
}

.containerLogOut section {
  grid-column: 2;
  grid-row: 2;
  height: 100%;
  width: 100%;
  align-content: center;
  justify-content: center;
  background-color: #F6F8FA;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 2px;
}

.containerLogOut section a {
  display: block;
  background-color: coral;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 2px;
  margin-top: 5px;
  text-align: center;
  padding: 5px;
  font-size: 10pt;
}

.containerLogOut button {
  background-color: coral;
  border-radius: 8px;
  font-size: 15pt;
  margin: 20px 0 30px 0;
}

@media screen and (max-width: 500px) {
  .header{
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .topLogo {
    width: 80%;
    height: 80%;
  }

  .infoOperacao {
    grid-column: 1;
    grid-template-columns: 1fr;
    grid-template-rows: auto ;
    grid-template-areas: "menuPrincipal"
                         "leftContainer"
                         "centerContainer"
                         "rightContainer";
    justify-items: center;
    font-size: 1.1rem;
  }

  .painelInf {
    display: none;
  }

  .statusOperacao {
    padding: 5px;
  }

  .statusOperacao input {
    width: 100%;
  }

  .statusOperacao h3 {
    margin-top: 0;
  }

  div .paineInfAdm {
    display: grid;
  }

  .couriersOciososBase ul {
    height: 150px;
  }

  .menuPrincipal ul {
    width: 261px;
  }


  .menuPrincipal ul li {
    float: none;
    font-size: 1.4rem;
  }

  .menuPrincipal ul li ul {
    white-space: initial;
    margin-left: -1%;
    width: 261px;
  }


  .menuPrincipal ul li ul li ul{
    margin-left: 0;
    width: 261px;
    margin-left: -1%;
    margin-top: 0;
  }

  .menuPrincipal ul li ul li {
    text-align: center;
  }

  .infoOperacao .statusOperacao p {
    font-size: 1.3rem;
  }

  .infoOperacao .statusOperacao li {
    font-size: 1.1rem;
    margin-left: 5%;
  }

  .infoOperacao .statusOperacao li:nth-child(1) {
    margin-top: 5px;
  }

  .infoOperacao .statusOperacao li:nth-child(4) {
    margin-bottom: 5px;
  }


  .couriersOciososBase {
    grid-column: 1;
  }

  .containerLogOut section{
      padding: 5px;
      background-color: coral;
      border: none;
      box-shadow: none;
  }

  .containerLogOut section button {
    width: 80%;
  }

  .containerLogOut section p {
    font-size: 16pt;
  }

  
  

}


@media screen and (max-width: 1579px) {

  .menuPrincipal a {
    font-size: 0.9em;
    padding: 2px;
  }

  .couriersOciososBase ul li{
    font-size: 13pt;
  }

  .statusOperacao ul li{
    font-size: 13pt;
  }

  .statusOperacao p {
    font-size: 13pt;
  }
}


/*** Encerra Menu ****/


/** container Conteudo **/


.container {
  grid-column: 2;
  width: 100%;
  justify-self: center;
  text-align: center;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: black 1px 1px 2px;
  margin-top: 25px;
  padding: 5px;
  grid-template-rows: 70px 1fr;
}


/***  fim container conteudo ***/


/*** Footer **/
.footer {
  justify-self: center;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: coral;
  border: 2px solid black;
}


.footer p{
  text-align: center;
  white-space: nowrap;
}


/** fim Footer **/


/** Trips  **/


section.remessasComprovante.container-rms-transferencia {
  font-size: 19pt !important;
  text-align: left;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

section.remessasComprovante.logins-transferencia {
  font-size: 16pt !important;
  grid-template-columns: 2;
}

section.data-trip-transferencia h2 {
  margin: 3px 0 3px 0;
  font-style: italic !important;
}

.boxTrip {
  border: 2px solid black;
  height: 290px;
  padding: 3px;
  text-align: center;
  background-color: coral;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: black 1px 0px 3px 0px;
}

.boxTrip a {
  overflow-y: scroll;
}

.boxTrip:hover {
    border: 2px solid crimson;
}

.container-home {
  justify-content: stretch;
}

.ContainerTrips {
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  gap: 15px;
  justify-content: center;
}

.tripAtrasada {
  background-color: rgb(224, 41, 41); 
}

.boxTrip.Atrasada:hover a {
  color: yellow;
}


/** fim Trips **/

/** Last Mile **/

.LastMile {
  padding: 15px;
  width: 40%;
  justify-self: center;
  border: 2px solid black;
  border-radius: 8px;
  background-color: coral;
  text-align: center;
}

.LastMile textarea {
  width: 60%;
}

.span-G {
  margin-bottom: 20px;
  font-size: 20pt;
  font-style: italic;
  font-weight: 600;
}

.span-M {
  margin-bottom: 20px;
  font-size: 17pt;
  font-style: italic;
  font-weight: 600;
}

.span-P {
  margin-bottom: 15px;
  font-size: 17pt;
  font-style: italic;
  font-weight: 600;
}

#clienteLista {
  font-size: 20pt;
  margin-top: 15px;
}

#clienteLista input {
  font-size: 20pt;
  width: 30%;
}

.div-rms-boxTrip {
  border: 2px solid black;
  margin-top: 10px;
  border-radius: 8px;
  background-color: coral;
  padding: 15px 3px 15px 3px;
  text-align: center;
  overflow-x: auto;
}

.box-rms-boxTrip {
  width: 95%;
  margin-top: 15px;
  align-items: start;
  justify-self: center;
  border-radius: 8px;
  padding: 10px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 320px));
  gap: 8px;
}



.ul-rms-boxTrip li {
  font-size: 15pt;
  margin-top: 3px;
}

.ul-rms-boxTrip p {
  font-size: 16pt;
}

.btn-Copy{
  padding: 2px;
  background-color: coral;
  border-radius: 6px;
}


.TipoBipagem {
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}



/** Fim Last Mile **/


/** Show Trip ***/

.details-Trip {
  border: 2px solid black;
  border-radius: 8px;
  background-color: coral;
  box-shadow: black 1px 1px 2px;
  justify-self: center;
  padding: 5px 15px 10px 15px;
  margin: 15px 0 25px 0;
  text-align: center;
}

.details-Trip p {
  font-size: 16pt;
  margin: 3px;
}

.FinishTrip {
  align-items: center;
  padding: 15px;
  text-align: center;
}

.FinishTrip span {
  font-size: 20pt;
  margin: 15px 0 15px 0;
  color: crimson;
  font-style: italic;
  font-weight: 600;


}

.FinishTrip textarea {
  width: 25%;
  font-size: 15pt;
  height: 300px;
}

/** Fim Show Trip **/

/** Producao **/

.boxProduction {
  width: 80%;
  background-color: coral;
  justify-self: center;
  justify-items: center;
  font-size: 17pt;
  padding: 15px;
  text-align: center;

}

.boxProduction input {
  display: inline;
  width: auto;
  margin: 5px 5px 15px 5px;
}

.boxProduction p {
  margin-bottom: 15px;
}

.extratoSupport {
  justify-items: center;
}

.ResumoProducao {
  width: 50%;
  border: 2px solid black;
  text-align: center;
  padding: 10px;
  margin: 20px 0 20px 0;
}

.ResumoProducao p {
  font-size: 20pt;
  line-height: 1.3em;
}

.tableProduction {
  justify-self: center;
  background-color: coral;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: black 1px 1px 2px;
  font-size: 17pt;
  text-align: center;
  padding: 1px;
  margin-bottom: 15px;
}

.tableProduction th {
  padding: 5px;
}

.tableProduction td {
  padding: 5px;
}


.container-Production h3 {
  margin-top: 15px;
}

.container-Production label {
  display: inline;
}

.h2-ProductionAll {
  margin-bottom: 25px;
}

@media screen and (max-width: 500px) {
  .tableProduction th{
    font-size: 14pt;
  }

  .tableProduction td {
    font-size: 12pt;
  }


}


/** Fim Production **/

/** Logins **/

.container-Logins {
  justify-items: center;
}

.container-Logins th {
  font-size: 17pt;
}

.container-Logins td {
  font-size: 16pt;
}

.td-login-busy {
  color: red;
}

 /** fim Logins **/

/** Create Courier **/
.container-CreateCourier {
  grid-template-rows: 70px 1fr 70px;
  justify-items: center;
}

.container-CreateCourier form {
  width: 95%;
  justify-content: center;
  grid-auto-rows: 70px;
}

.container-CreateCourier h3 {
  margin: 15px 0 15px 0;
}

.container-CreateCourier input {
  display: block;
  width: 279px;
  padding: 4px;
}

.container-CreateCourier label {
  font-size: 18pt;
}

.container-CreateCourier select {
  display: block;
  width: 279px;
  font-size: 15pt;
  margin: 0 auto;
}



/** Fim Create Courier **/

/** EditTrip **/

.container-EditTrip {
  justify-content: center;
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 70px 80px 1fr;

}

.container-EditTrip section {
  text-align: center;
  border: 2px solid black;
  border-radius: 8px;
  background-color: coral;
  box-shadow: black 1px 1px 2px;
  padding: 5px;
  align-self: center;
}

.container-EditTrip section p {
  font-size: 18pt;
}

.container-EditTrip input {
  margin: 15px 0 15px 0;
  width: auto;
}


.container-EditTrip button.btn-addRms {
  margin-bottom: 20px;
}


/** Fim Edit Trip **/

/** Painel Remessas **/

.container-PainelRemessas select {
  background-color: coral;
  height: auto;
}

/** fim Painel remessas **/

/** Emissao Contas **/

.container-EmissaoConta {
  justify-items: center;
}

.container-EmissaoConta label {
  font-size: 18pt;
  margin: 10px 0 10px 0;
}

.container-EmissaoConta select {
  margin-bottom: 0;
}

.container-EmissaoConta form {
  margin-top: 20px;
  justify-items: center;
}

.Agendamento-Conta {
  padding: 15px;
  margin-top: 10px;
  justify-items: center;
  width: auto;
  background-color: coral;
}

.Agendamento-Conta label {
  display: inline;
}

.boxProduction {
  padding: 10px;
  background-color: coral;
  width: auto;
  border-spacing: 10px 10px;
  margin-top: 20px;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: black 1px 1px 2px;
}

.boxProduction th {
  margin-right: 10px;
}

.tableMinhasContas {
  border-spacing: 15px;
}

@media screen and (max-width: 500px) {
  .tableMinhasContas {
    border-spacing: 2px;
  }

  .tableMinhasContas td {
    font-size: 14pt;
    padding: 2px;
  }

  .tableMinhasContas th {
    font-size: 14pt;
  }
}


/** fim Emissao de Contas **/

/** Requisicao e PDF **/

.container-Requisicao form input {
  width: auto;
  margin-bottom: 20px;
}

.container-Requisicao form {
  width: 500px;
}

.ulRequisicoesTotais {
  text-align: left;
  padding: 5% 15% 5% 15%;
  background-color: coral;
}

.ulRequisicoesTotais li{
  font-size: 1.5rem;
}


/** fim Requisicao e PDF**/

/** Devolucao **/

.container-Devolucao textarea {
  width: 100%;
}


/** fim Devolucao **/

/** Recebimento de Carga **/

.container-RecebimentoCarga button {
  margin-bottom: 15px;
}



/** fim recebimento de carga **/

/** Login **/

.headerLogIn {
  grid-column: 2;
  width: 60%;
  justify-self: center;
  padding: 5px;
  background-color: coral;
  grid-template-columns: 220px 5fr;
  text-align: center;
  align-items: center;
}

.headerLogIn span {
  font-size: 32pt;
  font-style: italic;
  font-family: "Grandstander", cursive;
}



.formLogIn {
  grid-column: 2;
  width: 60%;
  justify-self: center;
  height: 500px;
  align-content: center;
}

.formLogIn label {
  margin: 15px;
}

.formLogIn button {
  padding: 0 10px 0 10px;
}

.formLogIn input {
  width: 30%;
  height: 40px;
}

.topLogoLogIn {
  border: 2px solid black;
  border-radius: 8px;
  align-self: center;
  width: 100%;
  height: 100%;
  box-shadow: black 1px 1px 2px;
}

.footerLogIn {
  width: 60%;
  justify-self: center;
}


@media screen and (max-width: 500px) {
  .topLogo {
    width: 80%;
  }


  .headerLogIn .aTopLogo {
    width: 100%;
    height: 100%;
    margin: 0;
    justify-self: center;
  }

  .headerLogIn .aTopLogo img {
    width: 100%;
    height: 95%;
  }

  .headerLogIn span {
    display: none;
  }

 

  .headerLogIn {
    grid-column: 2;
    width: 80%;
    margin: 10px 0 10px 0;
    background-color: #F6F8FA;
    border: none;
    box-shadow: none;
    grid-template-columns: 1fr;
  }

  .formLogIn {
    grid-column: 2;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-content: center;
  }

  .formLogIn input {
    width: 100%;
  }

  .footerLogIn {
    width: 100%;
    justify-self: center;
  }


}
/** fim login **/

/** classes couriers **/

.MetricaCourier-Relatorio {
  align-items: center;
  align-content: center;
  grid-template-rows: 1fr;
  padding-bottom: 20px;
}

.MetricaCourier-Relatorio p {
  font-size: 17pt;
}

.MetricaCourier-Relatorio h3 {
  margin: 0;
}


@media screen and (max-width: 500px) { 
  .MetricaCourier-Relatorio {
    height: 200px;
  } 
}


/** Fim couriers **/

/** Tracking **/

.tableTrackingHeader{
  background-color: green;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: black 1px 1px 2px;
  border-spacing: 7px;
}

.tableTracking {
  background-color: coral;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: black 1px 1px 2px;
  border-spacing: 15px;
  margin-top: 10px;
}

.tableTracking tr td{
  margin-right: 20px;
}

#SingleRm {
  font-size: 20pt;
  color: black;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}

@media screen and (max-width: 500px) {
  .tableTrackingHeader {
    display: grid;
    border-spacing: 1px;
  }

  .tableTrackingHeader tr th {
    font-size: 13pt;
  }

  .tableTrackingHeader tr td {
    font-size: 11pt;
  }

  .tableTracking {
    border-spacing: 1px;
  }

  .tableTracking tr th {
    font-size: 12pt;
  }
  .tableTracking tr td {
    font-size: 12pt;
  }
}

/** fim Tracking **/

/** Comprovante **/

.Comprovante {
  grid-template-columns: 800px;
  justify-items: center;
  padding-top: 30px;
}

.headerComprovante {
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 170px;
  gap: 5%;
}
 
.LogoComprovante {
  width: 170px;
  height: 170px;
}

.headerComprovante h2 {
  font-size: 40pt;
  justify-self: center;
  align-self: center;
  white-space: nowrap;
}

.bodyComprovante {
  text-align: center;
}

.bodyComprovante h2 {
  margin-top: 20px;
}

.remessasComprovante {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 22pt !important;
  border: 1px dashed black;
}

.bodyComprovante p {
  font-size: 19pt;
}

.controleComprovante {
  justify-self: left;
}

/** Fim Comprovante **/

/** Controle de Entradas **/

.detailsRomaneioEntrada {
  padding: 15px;
  font-size: 17pt;
  background-color: coral;
}

.detailsRomaneioEntrada span {
  color: crimson;
}

.detailsRomaneioEntrada span span {
  color: black;
}

.column-1 {
  grid-template-columns: 1fr;
  align-items: center;
}

.columns-2 {
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 5px;
}

.columns-3 {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
  gap: 5px;
}

.divRmsControleEntradas {
  margin-top: 10px;
  background-color: coral;
  padding: 5px;
}

/** fim controle entradas **/

/** painel admin */

.formUpdateCliente {
  justify-content: center;
  justify-items: center;
}

.formUpdateCliente select {
  display: block;
}

#app {
  margin-bottom: 10px;
}



/** fim painel admin **/

/** Show courier **/

.containerShowCourier {
  grid-template-columns: 40% 40%;
  grid-template-rows: repeat(auto-fit, minmax(200px, 300px));
  gap: 8px;
}

.containerShowCourier section {
  padding: 10px;
}

.containerShowCourier span {
  margin-bottom: 5px;
}

.containerShowCourier span span {
  color: crimson;
}

.div-IndexCourier {
  padding: 25px;
}

.div-IndexCourier a:hover {
  color: black;
}

/** fim show courier **/

/** Find Package **/

.DivFindPackage {
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  border-radius: 8px;
}

.addRemessasToFind {
  grid-column: 2;
}

.InputFindPackage {
  grid-column: 2;
}

.PackagesFounds {
  grid-column: 1;
  grid-template-rows: 50px;
  text-align: center;
}

.PackagesnotFounds {
  grid-column: 3;
  grid-template-rows: 50px;
  text-align: center;
}

.InputFindPackage {
  text-align: center;
  padding-top: 50px;
  gap: 5px;
  grid-template-rows: 30px 50px;
}

.InputFindPackage input {
  font-size: 23pt;
  width: 100%;
  text-align: center;
  background-color: coral;
}

.DivFindPackage ul{
  margin-top: 20px;
  width: 60%;
  justify-self: center;
  list-style-type: none;
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
  background-color: coral;
  border-radius: 3.5%;
  text-align: left;
}

.DivFindPackage ul li {
  font-size: 18pt;
  margin-top: 2px;
}

.BGred {
  background-color: crimson;
}

.BGgreen {
  background-color: green;
}


/* BaixaCliente */

.divBaixaClienteCourier {
  justify-items: center;
}

@media screen and (max-width: 500px) {

  .divBaixaClienteCourier form {
    width: 100%;
    font-size: 1.5rem;
  }
  
  .divBaixaClienteCourier label[for=parentesco] {
    margin-top: 15px;
  }
  
  .divBaixaClienteCourier input, select {
    width: 100%;
    font-size: 16pt;
  }

  .divBaixaClienteCourier button {
    width: 80%;
  }

}

.tableBaixaCliente tbody tr:hover {
  background-color: coral;
}


.tableBaixaCliente {
  border-spacing: 0px;
  margin-bottom: 8px;
  animation: slideUp .7s;
}

.tableBaixaCliente thead tr {
  font-size: 16pt;
}

.tableBaixaCliente tbody tr {
  font-size: 16pt;
}

.btnCBaixaC {
  margin: 3px;
}

.informativoBaixaCliente {
  font-size: 16pt;
  font-style: italic;
  font-weight: bold;
  color: red !important;
  animation: pisca 1s infinite;
}

.headerBaixaCourier {
  grid-column: 2;
  justify-self: center;
  font-size: 25pt;
  margin-top: 30px;
  margin-bottom: 10px;
}

.headerBaixaCourier a {
  font-size: 30pt;
}


#camera {
  height: 300px;
  width: 100%;
  justify-items: center;
  grid-template-columns: 1fr;
}

#camera video {
  width: 100%;
  height: 100%;
}

canvas.drawing, canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.boxScan {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 97%;
  height: 1.3px;
  top: 140px;
  background-color: red;
}


.TabPainelCourier{
  color: black;
  border: none
}

.TabPainelCourier button:hover{
  background-color: inherit;
}

/** Search Lote **/




.div-rm-index-courier {
  display: grid;
  border: 1px solid black;
  padding: 3px;
  margin-top: 2px;
}

.div-rm-index-courier:nth-child(odd) {
  background-color: rgb(236, 227, 222);
}

.rm-vencida {
  border: 2px solid red;
}

#signature {
  display: block;
  margin: 10px auto;
  border: 1px solid black;
  transform: rotate(0deg);
  width: 200px;
  height: 150px;
}
