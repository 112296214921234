@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

.containerSignature {
  z-index: 1000;
  width: 100vw;
  height: 80vh;
  transform: rotate(360deg);
  animation: slideUp .7s;
}

.containerSignature button {
  width: 60vw;
}

.containerSignature button:nth-child(1) {
  background-color: rgb(131, 209, 14);
  margin-bottom: 20px !important;
} 

.containerSignature button:nth-child(3) {
  background-color: crimson;
}

.sigContainer {
  width: 95%;
  height: 95%;
  margin: 10px auto;
  box-shadow: 1px 1px 3px 0.5px black;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
}
/* .sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
} */